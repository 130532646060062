.img_wrapper:hover .description {
  display: flex;
  opacity: 1;
}

.img_wrapper:hover .icon {
  display: flex;
  opacity: 0.3;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffa500;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}

.menu-toggle {
  display: block;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.menu-items {
  display: none;
}

.menu-items.active {
  display: block;
}

.menu-items ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-items ul li {
  margin-right: 20px;
}

.menu-items ul li a {
  color: #fff;
  text-decoration: none;
}

.menu-items ul li.logout {
  margin-left: auto;
}

.navbar-menu {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navbar-menu li {
  margin-right: 20px;
}

.navbar-menu li:last-child {
  margin-right: 0;
}

.navbar-menu a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.navbar-menu a:hover {
  color: #f00;
}

.navbar-mobile-menu {
  display: none;
}

.navbar-mobile-menu-icon {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}
